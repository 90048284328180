<template>
  <div class="d-flex flex-column">
    <div class="d-flex align-center">
      <div class="slider-input">
        <v-text-field
          outlined
          dense
          hide-details
          @input.native="debounceFunc"
          v-model="inputMin"
          label-placeholder="Min"
        />
      </div>
      <vue-slider
        class="mx-3 flex-grow-1"
        :lazy="true"
        v-model="filter.range"
        :min="filter.min"
        :max="filter.max"
        :interval="filter.interval"
        :enable-cross="false"
        @change="updateValuesFromSlider"
      />
      <div class="slider-input">
        <v-text-field
          outlined
          dense
          hide-details
          @input.native="debounceFunc"
          v-model="inputMax"
          label-placeholder="Max"
        />
      </div>
    </div>
    <div
      class="my-2 d-flex justify-space-between align-center"
      v-if="filter.preset"
    >
      <div
        class="px-1"
        v-for="(preset, presetIndex) in filter.preset"
        :key="'preset_' + presetIndex"
      >
        <v-btn
          @click="updateRangeFromButton([preset.min, preset.max])"
          variant="primary"
          class="p-1 py-2"
          small
        >
          {{ preset.label }}
        </v-btn>
      </div>
    </div>
  </div>
</template>

<script>
import utilMethods from '@/services/utils';
import VueSlider from 'vue-slider-component';
import 'vue-slider-component/theme/default.css';

export default {
  components: {
    VueSlider
  },
  props: {
    filter: {
      type: Object,
      required: true
    }
  },
  data() {
    return {
      inputMin: 0,
      inputMax: 0,
      debounceFunc: null
    };
  },
  mounted() {
    this.inputMin = this.filter.range[0];
    this.inputMax = this.filter.range[1];
    this.debounceFunc = utilMethods.debounce(this.updateValuesFromInput, 1000);
  },
  methods: {
    updateValuesFromSlider() {
      this.inputMin = parseFloat(this.filter.range[0]);
      this.inputMax = parseFloat(this.filter.range[1]);
      this.updateRange([
        parseFloat(this.filter.range[0]),
        parseFloat(this.filter.range[1])
      ]);
    },
    updateValuesFromInput() {
      this.inputMin =
        this.inputMin && !isNaN(this.inputMin)
          ? Math.max(parseFloat(this.inputMin), this.filter.min)
          : this.filter.min;
      let newMin = this.inputMin;

      this.inputMax =
        this.inputMax && !isNaN(this.inputMax)
          ? Math.min(parseFloat(this.inputMax), this.filter.max)
          : this.filter.max;
      let newMax = this.inputMax;

      if (newMin != this.filter.range[0]) {
        if (newMin > newMax) {
          newMin = newMax;
          this.inputMin = newMin;
        }
      }
      if (newMax != this.filter.range[1]) {
        if (newMax < newMin) {
          newMax = newMin;
          this.inputMax = newMax;
        }
      }

      this.updateRange([newMin, newMax]);
    },
    updateRangeFromButton(range) {
      this.inputMin = parseFloat(range[0]);
      this.inputMax = parseFloat(range[1]);
      this.updateRange(range);
    },
    updateRange(range) {
      this.filter.range = range;
      this.$emit('updateFilter', {
        range: this.filter.range,
        id: this.filter.id
      });
    }
  }
};
</script>

<style lang="scss" scoped>
.slider-input {
  width: 100px;
}
</style>
